$kgen-primary: #01486B;
$kgen-active: #f36e21;
$kgen-secondary: #00AEEF;
$kgen-white: #fff;
$kgen-black: #000;
$kgen-grey: #858585;
$kgen-light-grey: #e1e1e1;
$kgen-articles-bg: #001726;
$full-height: 110vh;
$navigation-height: 100px;
