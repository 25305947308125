.Stats-loader,
.Stats-loader:before,
.Stats-loader:after {
  border-radius: 50%;
}

.dates {
  width: 100% !important;
  color: #656565 !important;
  height: 35px !important;
}

.dates:focus {
  box-shadow: none !important;
}

.rdt {
  width: 100% !important;
}

.content-heading {
  font-size: 30px !important;
  font-family: 'Mark-Bold' !important;
  color:#01486B !important
  // font-size: 1.5rem;
  // line-height: 1.1;
  // color: #929292;
  // font-weight: 400;
}
.content-subtitle {
  font-size: 16px;
  font-family: 'Mark-Light' !important;
  color:#01486B;
  // font-size: 1.1rem;
  // line-height: 1.1;
  // color: #929292;
  // font-weight: 400;
  margin-top: 5px;
}

.Stats-loader {
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.Stats-loader:before,
.Stats-loader:after {
  position: absolute;
  content: '';
}
.Stats-loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #01486B;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.Stats-loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #01486B;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
