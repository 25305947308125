@import 'theme';

.nav-wrapper {
  position: relative;
  nav.custom {
    background-color: #01486B;
    z-index: 999;
      .navbar-toggler-icon {
        padding: 15px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
      .navbar-toggler {
        padding: 0.25rem 0.45rem;
        // border-color: rgb(255, 255, 255);
        margin-right: 12px;
      }
      .nav-content {
        .navbar-nav .nav-link {
          color: $kgen-white;
          font-size: 16px !important;
          font-family: 'Mark-Medium' !important;
        }
      }     
  }
}

.nav-active-link {
  color: red !important;
}



.tilted {
  display: block;
  position: absolute;
  border-top: 100px solid $kgen-primary;
  border-right: 99vw solid transparent;
  z-index: 8;
  overflow: hidden;
}



.navbar-brand .nav-item {
  list-style-type: none !important;
}

.nav-link.user {
  margin-top: -12px;
}

.custom .navbar-nav .active > .nav-link,
.custom .navbar-nav .nav-link.active,
.custom .navbar-nav .nav-link.show,
.custom .navbar-nav .show > .nav-link {
  color: $kgen-secondary;
}

.custom .navbar-nav .nav-link:focus,
.custom .navbar-nav .nav-link:hover {
  color: $kgen-white;
}

.dropdown-item:focus,
.dropdown-item:hover
{
  color:#FFFFFF !important;
  text-decoration: none;
  background-color:#01486B !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
opacity: 1;
}

.dropdown-item1 {
  display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    // font-weight: 400;
  font-family: 'Mark-Light';
  color:#1E1E1E;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}


@media (min-width:992px) {
  .logoHeader {
   display: none;
  }
}


@media (max-width: 992px) and (min-width: 240px) {
  .nav-link {
    text-align: center;
  }
  .nav-btn {
    margin: 8px 0;
  }
  .nav-item {
    text-align: center;
  }

  .nav-wrapper {
    position: initial;
    nav.custom {
      // height: 80px;
      padding: 0.5rem 0;
      .container {
        max-width: 100%;
        margin: 0;
        padding: 0 1rem;

        .nav-content {
          margin-top: 0;
        }
      }
    }
  }

  .tilted {
    display: none;
  }
  .navbar-brand {
    position: initial !important;
    padding: 0 !important;
    margin: 0 !important;

    img {
      height: 45px !important;
      width: 45px !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

@media (min-width: 576px) {
  .nav-wrapper {
    nav.custom {
      // height: 70px;
    }
  }
  .navbar-brand {
    padding: 0;
    margin: 0;

    img {
      height: 55px;
      width: 55px;
      padding: 0;
      margin: 0;
    }
  }
}


@media(max-width:1100px) and (min-width:992px) {
  .nav-wrapper {
    padding:0px 0px !important  }
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .nav-wrapper {
    position: relative;
     padding: 0px 70px;

    nav.custom {
      height: 100px;
      width: 100%;
      // border-radius: 25px !important;
      border-bottom-left-radius: 25px !important;
      border-bottom-right-radius: 25px !important;
        .nav-content {
          // margin-top: 50px;
        }
    }
  }

  .navbar-expand-lg .navbar-nav .nav-btn {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .navbar-straight .navbar-brand {
    position: absolute;
    top: 30px;

    img {
      height: 100px;
      width: 100px;
    }
  }
  .nav-link.user {
    margin-top: -12px;
  }
}

.menu-item {
  color: #01486B !important;
  font-family: 'Mark-Medium';
  font-size: 23px;
  margin: 15px 0 15px 0px;
  padding: 0.5rem 0.5rem !important;
}

.menu-item:focus,
.menu-item:hover,
.menu-item:active{
   color:#FFFFFF !important;
   text-decoration: none !important;
background-color:#01486B !important;
}



@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  // .applicationsSection {
  //   margin-top: 230px !important;
  // }
  // .tabsSection {
  //   margin-top: 240px !important;
  // }

  .mentorMeetings {
    padding: 2rem 0 0 0 !important;
    margin: 0 !important;
  }
}
@media (min-width: 810px) and (max-width: 1080px) {
  .menuSection {
    width: 15% !important;
  }
  .tabsSection {
    width: 80% !important;
    margin-left: 80px !important;
  }
  .applicationsSection {
    width: 80% !important;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .nav-wrapper  {
    nav.custom {
        .nav-content {
          margin-top: 10px;
          .navbar-nav .nav-link {
            font-size: 16px !important;
            font-family: 'Mark-Medium !important';
            line-height: 20px;
            color:#FFFFFF;
          }
          .nav-btn {
            font-size: 13px !important;
          }
        }
      
    }}
  
}

@media (min-width: 1220px) {
  .navbar-expand-lg .navbar-nav{
    // grid-gap: 20px !important;
     margin-top: 25px;
  }

}

 @media (min-width: 1220px) {
  .navbar-expand-lg .navbar-nav {
column-gap: 8px !important;
 }
 }

.logoHeader{
  margin-left: 20px !important;
  width: 40px;
  object-fit: contain;
}

.listItemLink{
  color: white;
  cursor: pointer;
}
.activeLink {
  background-color: #01486B !important;;
  box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    opacity: 1;
    display: block;
    width: 100%;
    // padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #FFFFFF;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
  
    font-family: 'Mark-Medium';
    font-size: 23px;
    margin: 15px 0 15px 0px;
    padding: 0.5rem 0.5rem !important;
}

.activeLink:hover {
  color: #FFFFFF;
}


html{
  scroll-behavior: smooth;

}

.dropdown-item1:hover {
  background-color: #f5f5f5;
  color: #1E1E1E

}