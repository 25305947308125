@import 'theme';

.container-about-us {
  background: #FFFFFF;
  color: $kgen-white;
  // padding: 20px 0 10px;

  .partner-single {
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 850px) {
      padding: 50px 0px 50px 0px;
    }
    .icon {
      //   float: left;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
