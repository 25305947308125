@import './fonts.scss';
@font-face {
  font-family: 'Mark-Regular';
  // font-weight: 450;
  font-style: normal;
  src: url(../fonts/Mark-Regular.ttf) format('truetype');
}

.anticon {
  vertical-align: 2px !important;
}
.main-container {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.back-to-home {
  float: right;
}

.loading-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f12b;
}

.loading-container .middle-loading {
  text-align: center;
  max-width: 50%;
}

.ant-upload {
  width: 99% !important;
  border-radius: 7px !important;
  height: 35px !important;
  .ant-btn {
    width: 100% !important;
    border-radius: 7px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    opacity: 1 !important;
    height: 35px !important;
  }
}

.ant-upload {
  .ant-btn:hover {
    border-color: #01486b !important;
    color: #01486b !important;
  }
  .ant-btn:hover {
    border-color: #01486b !important;
    color: #01486b !important;
  }
  .ant-btn:active {
    border-color: #01486b !important;
    // color:#01486B !important
  }
  .ant-btn:focus {
    border-color: #01486b !important;
    // color:#01486B !important
  }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  padding: 10px 50px 10px 50px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  padding: 10px 50px 10px 50px;
}

.ant-menu-dark .ant-menu-item > span > a {
  color: #fafafa !important;
  font-family: 'Mark-Medium';
  font-size: 13px !important;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item {
  color: #fafafa !important;
}

.ant-tabs-tab:hover {
  color: #01486b !important;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.ant-layout-sider-children .logo {
  text-align: center;
  margin: 1rem 0;
}

.site-layout .site-layout-background {
  background: #fff;
}

// .contentLayout {
//   margin: 24px 16px;
//   background-color: #fff;
//   padding: 24,
// }

// @media (max-width:600px) {
//   .contentLayout {
//     margin:10px;
//     padding:5px;
//   }
// }


.header-dashboard {
  float: right;
}

.ant-layout-sider-children {
  background-color: #01486b !important;
}

// @media (max-width:600px) {
//  .ant-layout-sider {
//   width: 60px !important;
//   max-width: 60px !important;
//   min-width: 60px !important;
//  }
// }

.ant-btn-primary {
  background: #01486b !important;
  border-color: #01486b !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #01486b !important;
}

.ant-btn-primary {
  background: #00aeef;
}

.resume-loading {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}

.ant-tabs-content-holder {
  padding: 5px 10px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 14px 8px 5px;
}

//**********************************************************************************************************************
//SLICE
//**********************************************************************************************************************

//OVERALL
body {
  font-family: 'Open Sans', sans-serif;
  overflow: none;
  // background-color: #D9D9D9 !important;
}

input,
textarea,
a,
button {
  //-webkit-appearance: none;
  border-radius: 0;
}

button,
a {
  font-size: 14px !important;
  text-decoration: none !important;
}

.btn-kgen-secondary {
  color: $kgen-white !important;
  background-color: #01486b !important;
  // border-color: $kgen-secondary;
  border-radius: 7px !important;
}

.visited {
  color: $kgen-active !important;
}

.secondary {
  color: #00aeef !important;
}

.grey {
  color: $kgen-grey !important;
}

.primary {
  color: $kgen-primary !important;
}

.btn-kgen-active {
  color: $kgen-white !important;
  background-color: $kgen-active;
  border-color: $kgen-active;
}

.btn-kgen-white {
  color: $kgen-primary !important;
  background-color: $kgen-white;
  border-color: $kgen-white;
}
.btn-kgen-white a {
  color: #333;
}

.btn-kgen-light-grey {
  color: $kgen-primary !important;
  background-color: $kgen-light-grey;
  border-color: $kgen-light-grey;
}

.btn-kgen-grey {
  color: $kgen-white !important;
  background-color: $kgen-grey;
  border-color: $kgen-grey;
}

#resetPassword,
#forgotPassword,
#adminBusinessLogin,
#register,
#login,
#jobPostings,
#article {
  padding-top: 100px;
  min-height: calc(#{$full-height} - #{$navigation-height} - 100px);
  background: #fff;
}

#register .ant-picker {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 58px;
  font-weight: 800;
  color: $kgen-primary;
  text-align: center;
  margin: 0 auto 30px;
  word-break: break-all;
}

.subtitle {
  font-size: 17px;
  color: $kgen-primary;
  text-align: center;
  margin: 0 auto 39px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.kgen-err-msg {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1.5715;
}

.content {
  position: relative;
}

section#articles {
  padding: 30px 0;
  background: #d9d9d9;
}

.required::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  text-transform: capitalize;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  // content: '*';
}

// QUESTIONS
.questions-container {
  padding: 20px 0;
}
.questions-container .questions-item {
  margin: 25px 0;
}
.certificate-container {
  margin-bottom: 50px;
  position: absolute;
}

.certificate-container {
  position: relative;
  text-align: center;
  color: white;
  margin: 150px;
}

.centered {
  position: absolute;
  top: 53.6%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered h1 {
  margin: 0;
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
}
.centered-quiz-name {
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.3;
  width: 65%;
}
.centered-quiz-name p {
  font-family: 'Open Sans';
  font-weight: 300;
  color: #102935;
  font-size: 23.5px;
  text-align: center;
  margin: 0;
}
.centered-date {
  position: absolute;
  top: 82.5%;
  left: 35.5%;
  transform: translate(-50%, -50%);
}
.centered-date p {
  font-family: 'Open Sans';
  font-weight: 400;
  color: #102935;
  font-size: 11px;
  text-align: center;
  margin: 0;
}
.centered-mentor-name {
  position: absolute;
  top: 82.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-mentor-name p {
  font-family: 'Open Sans';
  font-weight: 400;
  color: #102935;
  font-size: 11px;
  text-align: center;
  margin: 0;
}
.centered-ctr-name {
  position: absolute;
  top: 82.5%;
  left: 64.5%;
  transform: translate(-50%, -50%);
}
.centered-ctr-name p {
  font-family: 'Open Sans';
  font-weight: 400;
  color: #102935;
  font-size: 11px;
  text-align: center;
  margin: 0;
}
.failed-msg {
  text-align: center;
  margin: 100px 0;
}
.failed-msg a {
  color: #09abed;
  text-decoration: underline !important;
}
.bulma_box__1T2Q9 {
  // padding-top: 2.4rem !important;
}
.bulma_hero-body__3rgxx {
  background: #01486B;
}
.styles_sectionIcon__1gjLR {
  background: #01486B !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #d7e9f6 !important;
  // background-color: var(--fc-today-bg-color, #d7e9f6) !important;
}
section.styles_appContainer__3-Sun.bulma_section__lsiE3 {
  padding: 0;
}
figure.bulma_image__wPw81.bulma_is-128x128__2P3WV {
  display: flex;
  align-items: center;
}

.bulma_image__wPw81 img.bulma_is-rounded__33HAW {
  border-radius: 100% !important;
  height: 100px !important;
  width: 100px !important;
  object-fit: cover !important;
}
.bulma_hero__1bjCM.bulma_is-primary__1jh74 .bulma_title__24LjN {
  font-family: 'Mark-Bold';
}
.sa-question__select {
  padding: 5px !important;
}
.styles_contactListItemContent__itzcS  {
  font-family: 'Mark-Medium';
}

// button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
//   display: none;
// }

.ant-layout-header {
   padding: 0 20px !important;

}
.styles_sectionTitle__2b35m {
  font-family: 'Mark-Medium';
  color:#01486b
}

.bulma_tag__xgEBq:not(body).bulma_is-primary__1jh74  {
  background-color: #d9d9d9 !important;
  color: #1e1e1e !important;
  
}

.bulma_box__1T2Q9 {
  font-family: 'Mark-Light';
  color: #1e1e1e !important;
}
.ant-collapse-item .ant-collapse-header {
  font-size: 20px !important;
  color: #01486B !important;
}

.ant-collapse-header .ant-collapse-arrow {
  font-size: 20px !important;
  color: #01486B !important;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: white !important;
  background: #01486B !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  font-family: 'Mark-Medium' !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding:10px 50px 10px 50px !important;
  font-family: 'Mark-Medium' !important;
  color:#01486B;
  
  
}

.ant-upload.ant-upload-select-picture-card {
  padding: 35px !important;
  border: 1px solid #fafafa !important;
  background-color: #fafafa !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border: 1px solid #01486B !important;
}
.ant-upload.ant-upload-select-picture-card:active {
  border: 1px solid #01486B !important;
}

@media (max-width: 768px) and (min-width: 240px) {
  #adminBusinessLogin .border-right,
  #login .border-right {
    border-right: none !important;
  }
}

@media (max-width: 992px) and (min-width: 240px) {
  $navigation-height: 60px;

  #resetPassword,
  #forgotPassword,
  #adminBusinessLogin,
  #register,
  #login,
  #jobPostings {
    min-height: calc(#{$full-height} - #{$navigation-height} - 100px);
  }
}

// @media (min-width: 300px) and (max-width: 600px) {
//   //Put your CSS here for 200px to 767px width devices (cover all width between 200px to 767px //
//   .ant-layout-sider {
//     max-width: 30% !important;
//     min-width: 10% !important;
//     // flex:0 0 50px !important
//   }
// }

// @media (max-width:600px) {
//   .ant-layout-sider {
//     max-width: 30px !important;
   
//   }
// }

@media (max-width: 600px) {
  .anticon .anticon-menu-fold .trigger {
    display: none;
  }
}

// @media (max-width: 850px) {
//   .ant-layout-sider {
//     min-width: 13% !important;
//     max-width: 13% !important;
//   }
// }

.ant-scrolling-effect {
  overflow: visible !important;
}

.ant-modal-content {
  border-radius: 10.5366px !important;
  // bottom: 40px !important;
}

// .ant-layout.ant-layout-has-sider > .ant-layout,
// .ant-layout.ant-layout-has-sider > .ant-layout-content {
//   width: 50%;
// }

@media (min-width: 300px) and (max-width: 600px) {
  .MUIDataTableBodyCell-stackedCommon-69 {
    width: calc(61%) !important;
  }
}

@media (max-width: 800px) {
  .nav-btn {
    margin: 4px 0;
  }
}

@media (max-width: 700px) {
  .buttons {
    display: inline-grid !important;
    margin-top: 10px !important;
  }
}

@media (max-width: 700px) {
  .buttonEdit {
    width: 70%;
  }
  .buttonView {
    width: 70%;
  }
  .buttonBack {
    width: 75%;
  }
  .divButton {
    margin-top: 10px;
  }
}

@media (max-width: 700px) {
  .ant-drawer-content-wrapper {
    width: 345px !important;
  }
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  margin-top: 7px;
}

.ql-container.ql-snow {
  box-shadow: 0px 3px 6px #00000029 !important;
  height: 100px;

}
.ant-picker-input > input {
  color: #707070 !important;
  font-size: 12px !important;
  font-family: 'Mark-Light' !important;
}
.form-area {
  border-radius: 7px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;
}

.form-area:focus {
  border-color: #01486B !important;
  box-shadow: none !important;
  // color: #01486B !important;
}
.form-area:active {
  border-color: #01486B !important;
  // color: #01486B !important;
}
.form-area:hover {
  border-color: #01486B !important;
  // color: #01486B !important;
}
.form-area:focus-visible {
  border-color: 'inherit' !important;
  // color: #01486B !important;
}

.form-control {
  border-radius: 7px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;
 height: 34px !important;
}



.addSellect {
  display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 7px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    opacity: 1 !important;
    height: 35px !important;
}

.addSellect:focus {
  border-color: #01486B !important;
  box-shadow: none !important;
 color: #01486B !important;
 border-radius: 7px !important;
}
.addSellect:active {
  border-color: #01486B !important;
   color: #01486B !important;
   border-radius: 7px !important;
}
.addSellect:hover {
  border-color: #01486B !important;
   color: #01486B !important;
   border-radius: 7px !important;
}


.textArea-control {
  border-radius: 7px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;
}
.textArea-control:focus {
  border-color: #01486B !important;
  box-shadow: none !important;
}
.textArea-control:active {
  border-color: #01486B !important;
}
.textArea-control:hover {
  border-color: #01486B !important;
}
.textArea-control:focus-visible {
  outline: none !important;
}
.form-control:focus {
  border-color: #01486B !important;
  box-shadow: none !important;

}
.form-control:active {
  border-color: #01486B !important;

}
.form-control:hover {
  border-color: #01486B !important;

}
.inputForm {
  border-radius: 7px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;
  height: 34px !important;

}

.inputForm:focus {
  border-color: #01486B !important;
  box-shadow: none !important;
}
.inputForm:active {
  border-color: #01486B !important;
}
.inputForm:hover {
  border-color: #01486B !important;
}
.inputGroupForm {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-radius: 7px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;
 height: 34px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius:0px !important;
}



.inputGroupForm:focus {
  border-color: #01486B !important;
  box-shadow: none !important;
}
.inputGroupForm:active {
  border-color: #01486B !important;

}
.inputGroupForm:hover {
  border-color: #01486B !important;

}

.selectGroupForm {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
   box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;
   height: 34px !important;
}
.selectGroupForm:focus-visible {
  border-color: transparent !important;
  outline:1px solid #D9D9D9 !important;
}

.selectGroupForm:focus {
  border-color: transparent !important;
  box-shadow: none !important;
border: 1px solid #D9D9D9 !important; 
box-shadow: 0px 3px 6px #00000029 !important;
opacity: 1 !important;
}
.selectGroupForm:active {
  border-color: transparent !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;
  border: 1px solid #D9D9D9 !important;

}
.selectGroupForm:hover {
  border-color:transparent !important;
  border: 1px solid #D9D9D9 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;

}



.minusButton:hover {
  color: #01486B !important;
  border-color: #01486B !important;
}

.ant-upload-list-item-name {
  color: #01486B !important;
}

.ant-upload-list-picture .ant-upload-list-item {
  width: 99%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px !important;
  opacity: 1;
}

//  .ant-btn:hover {
//   border-color: #01486B !important;
//   color: #01486B !important;
//  }
//  .ant-btn:focus {
//   border-color: #01486B !important;
//   color:#01486B !important
//  }

.ant-btn .form-control:hover {
  color: #01486B;
  border-color: #01486B !important;
}

.css-1gsv261 {
  border-bottom: 0px solid !important;
}

.MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #01486B !important;
  color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 10px 15px 10px 15px !important;
}

.tabs {
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  font-size: 13px !important;
  font-family: 'Mark-Book' !important;
  text-transform: none !important;
  min-height: 44px !important;
  color: #01486B !important;
  border: 0.5px solid #ebebeb !important;
  padding: 10px 15px 10px 15px !important;
}

.MuiButtonBase-root-MuiTab-root {
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  font-size: 13px !important;
  font-family: 'Mark-Book' !important;
  text-transform: none !important;
  min-height: 44px !important;
  color: #01486B !important;
  border: 0.5px solid #ebebeb !important;
  padding: 10px 15px 10px 15px !important;
}

.MuiTabPanel-root {
  padding: 0px !important;
}

// .bBfHpH {
//   left: -24px !important;
// }

.bhHnmT {
  margin: 0px 0px !important;
}

.btn:focus {
  box-shadow: none !important;
}

// @media (min-width:1280px) {
//   .dZkckO {
//     position: absolute !important;
//     right: 17px !important;
//   } 
// }





// .MuiTabs-indicator {
//   background-color: white !important;
//   height: 0px !important;
// }

.MuiTablePagination-actions {
  margin-left: 0px !important;
}

.register-Container {
  padding: 30px 160px 30px 160px;
}

@media (max-width: 600px) {
  .register-Container {
    padding: 30px 15px 30px 15px;
  }
}

@media (max-width:800px) and ( min-width:600px){
  .register-Container {
    padding: 30px 25px 30px 25px;
  }
}


@media (max-width: 600px) {
  .container-Login  {
    padding: 30px 15px 30px 15px !important
  }
}

.container-Login {
  padding: 30px 100px 30px 160px;
}



@media (max-width:600px) {
  .img-Login {
    display: none;
  }
}


@media (max-width: 900px) and (min-width:600px) {
  .container-Login {
    // width: 90% !important;
  padding: 50px 30px 30px 30px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  font-family: 'Mark-Light' !important;
  color: #ffffff !important;
  font-size: 18px !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  font-size: 16px !important;
  font-family: 'Mark-Light' !important;
  color: #ffffff !important;
}

.styles_faq-row-wrapper__3vA1D h2 {
  font-family: 'Mark-Bold' !important;
  font-size: 26px !important;
}

.MUIDataTableHeadCell-data-49 {
  font-family: 'Mark-Medium' !important;
}
.ant-tabs {
  font-family: 'Mark-Medium' !important;
}

// .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
//   color: #fff;
//   background: #01486B !important;
//   border-color: #01486B !important;
// }
// .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
//   background: #01486B !important;
//   border-color: #01486B !important;
// }


.ant-radio-button-wrapper:hover {
  position: relative;
  /* color: #1890ff; */
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  /* color: #1890ff; */
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}


.ant-drawer-header {
  color: #01486B !important;
  font-family: 'Mark-Bold' !important;
}
.ant-drawer-title {
  color: #01486B !important;
  font-family: 'Mark-Bold' !important;
  font-size: 18px !important;
}

.MUIDataTableToolbar-titleRoot {
  color:#01486B;
  font-family: 'Mark-Medium' !important;
}

.swal2-styled.swal2-default-outline:focus {
  box-shadow: none;
}

// .MuiTypography-root { color:#01486B;
//   font-family: 'Mark-Bold' !important; }

.swal2-title {
  color:#1e1e1e !important;
  font-family: 'Mark-Light' !important;
  font-size: 28px !important;

}
.swal2-html-container {
  color: #1e1e1e !important;
  font-family: 'Mark-Light' !important;
}

.styles_cvContainer__2q1j9 {
  padding-top: 40px !important;
}


.ant-picker-input>input::placeholder {
  color: #707070 !important;

}

@media (min-width: 900px) {
  .MuiTableCell-body  {
    max-width: 150px !important;
    overflow-wrap: break-word !important;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector  {  
 border-radius: 7px !important;
  box-shadow:0px 3px 6px #00000029;
  opacity: 1,
}

.ant-select-multiple .ant-select-selector {
  padding: 3px 0px !important
}

.ant-select-multiple .ant-select-selection-placeholder {
  color:#707070
}


.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #01486B !important;
}

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {box-shadow: inherit !important; border-color: inherit !important; }

  .ant-select-selection-placeholder  {
    color:#707070
  }

  .ant-tag-close-icon {
    font-size: 13.5px !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color:  #eef4fc !important;
  }
 
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }


