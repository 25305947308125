@import 'theme';
@import './main.scss';
@import './fonts.scss';

.jobCards {
  // width: 339px;
  width: 100%;
  // height: 575px;
  background: #ffffff;
  border-radius: 12px !important;
  // box-shadow: 0px 8px 18px rgba(0,0,0,0.1);
}
.trainingCards {
  // width: 339px;
  width: 100%;
  // height: 550px;
  background: #ffffff;
  border-radius: 12px !important;
  // box-shadow: 0px 8px 18px rgba(0,0,0,0.1)
}

.postings-container {
  padding: 50px 0;
}

.name {
  color: #01486b;
  font-size: 24px;
  font-family: 'Mark-Bold';
  line-height: 29px;
  height: 100px;
}
.nameTraining {
  color: #01486b;
  font-size: 24px;
  font-family: 'Mark-Bold';
  line-height: 29px;
  height: 100px;
}

@media (max-width: 1120px) and (min-width: 600px) {
  .name {
    height: 150px;
  }
}

@media (max-width: 1120px) and (min-width: 600px) {
  .nameTraining {
    height: 115px;
  }
}

.data {
  height: 300px !important;
}

.dataTraining {
  height: 200px !important;
}

@media (max-width: 1120px) and (min-width: 600px) {
  .data {
    height: 460px !important;
  }
}

@media (max-width: 1120px) and (min-width: 600px) {
  .dataTraining {
    height: 260px !important;
  }
}

.companyLogo {
  width: 61px;
  height: 64px;
  border-radius: 13px;
  margin-left: 20px;
  margin-top: 20px;
  object-fit: cover;
}

.subtitle {
  font-size: 17px;
  color: $kgen-primary;
  text-align: center;
  margin: 0 auto 39px;
}

.filter-table {
  min-width: 1100px !important;
  padding: 0;
  margin: 15px 0 15px 5px;
}

.filter-table-title-row {
  margin-bottom: 40px;

  th {
    border: none !important;
    padding-top: 0 !important;
    padding-bottom: 40px !important;
  }
}

.filters {
  min-width: 1110px !important;
  margin-left: 20px;
  margin-top: 5px;
}

.filter-options {
  padding: 15px !important;
}

.filter-options:hover {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.filter {
  display: flex;
  // flex-direction: column;
  gap: 10px;
  width: 33.33%;
  padding: 5px 0px !important;
  // height: 80%;
}

.custom-textinput {
  width: 95% !important;
  border-bottom: 1px solid $kgen-secondary !important;
  outline: none;
}

@media (max-width: 868px) and (min-width: 240px) {
  .filter {
    width: 100%;
  }
  .jobCards {
    width: 100%;
  }
}

.filter:hover {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.filterOptions {
  width: 100%;
  margin: 5px 0 15px 7px;
}

.jobCardsLabel::before {
  position: absolute;
  top: 50px;
  right: -2%;
  content: '';
  background: #f36e21;
  height: 11px;
  width: 15px;
  transform: rotate(45deg);
  @media (min-width: 1200px) {
    right: -1.7%;
  }
}

.jobCardsLabel::after {
  position: absolute;
  content: attr(id);
  top: 20px;
  right: -3%;
  padding: 0.5rem;
  border-radius: 6px;
  width: 10rem;
  background: #f36e21;
  color: white;
  text-transform: capitalize;
  text-align: center;
  font-family: 'Mark-Book';
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
  @media (min-width: 1200px) {
    right: -2.3%;
  }
}

.jobCards:hover {
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.trainingCards:hover {
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.jobOpening-email {
  color: #469ddb !important;
  font-size: 14px !important;
  font-family: 'Mark-Light' !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.jobLabels {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.jobText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#pTag {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
#pfield {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#dTag {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 80px;
}

.card-elements {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.cateogry-skills {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 80px;
}

.singleJobImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  // width: 50%;
}

// .jobs {
//   padding: 33px 15px 33px;
// }

.table {
  width: 90% !important;
}

.jobs:hover {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.filters th,
.filters td {
  padding: 0.55rem;
  padding-left: 0;
}

.filter-icon {
  display: inline-block;
  // max-width: 25px;
  max-height: 25px;
  margin-right: 5px;
  color: $kgen-grey;
  width: 5%;

  * {
    height: 100%;
    width: 100%;
    object-fit: none;
  }
}

.filter-name {
  display: inline-block;
  // max-height: 25px;
  font-size: 14px;
  font-weight: bold;
  color: $kgen-primary;
  margin-right: 3px;
  width: 35%;
}

.filter-select {
  display: inline-block;
  max-height: 25px;
  border: none;
  // max-width: 95%;
  // min-width: 75px;
  padding-left: 10px;
  width: 60%;

  * {
    // background: #fff url("./shigjeta.png") no-repeat right center;
    border: none;
    font-weight: bold;
    color: $kgen-secondary;
    font-size: 14px;
    box-shadow: none;
    max-height: 25px;
    padding: 0;
    padding-right: 23px;
    vertical-align: baseline;
    text-align: center;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.custom-select:hover {
  cursor: pointer;
}

// .filter-table-content-row:first-child {
//   th,
//   td {
//     border-bottom: 1px solid $kgen-light-grey !important;
//   }
// }

.filter-table-content-row {
  th,
  td {
    border: none !important;
    border-bottom: 1px solid $kgen-light-grey !important;
    //padding: 15px 0.75rem;
    padding: 33px 15px 33px;
  }
}

.filter-table-content-row:last-child {
  th,
  td {
    border-bottom: 1px solid $kgen-light-grey !important;
  }
}

.filter-table-data {
  max-height: 35px;
  display: inline-block;
}

.filter-table-data-line {
  display: block;
  // font-size: 13px !important;
  // height: 42.43px !important;
}

.one-line {
  height: 42.43px !important;
}

.filter-table-avatar-square {
  max-width: 35px;
  max-height: 45px;
  display: inline-block;
  margin-right: 7px;
  vertical-align: bottom;

  * {
    height: 100%;
    width: 100%;
    object-fit: fill;
    vertical-align: baseline;
  }
}

.kgen-pagination {
  text-align: center;
  // margin-top: 60px;

  .page-link {
    color: #01486b !important;
    border: 1px solid $kgen-light-grey !important;
    border-radius: 9px !important;
    font-family: 'Mark-Bold';
    font-size: 16px !important;

    &:focus {
      color: $kgen-secondary;
      border: 1px solid $kgen-secondary !important;
      border-radius: 9px !important;
    }
  }

  .page-item.active .page-link {
    background-color: #00aeef !important;
    color: #01486b !important;
    border: 1px solid #00aeef !important;
    border-radius: 9px !important;
    width: 40px;
  }

  nav {
    display: inline-block;
  }

  p {
    display: inline-block;
  }

  .jobLimit {
    color: #01486b !important;
    border: 1px solid $kgen-light-grey !important;
    border-radius: 9px !important;
    font-family: 'Mark-Bold';
    font-size: 16px !important;

    &:focus {
      color: #01486b !important;
      border: 1px solid $kgen-light-grey !important;
      border-radius: 9px !important;
    }
  }

  select {
    display: inline-block;
    padding: 0.4rem 0.75rem;
    color: $kgen-primary;
    // border: 1px solid $kgen-light-grey !important;
    vertical-align: baseline;
    line-height: 1.25;
    font-size: 18px;
    border-radius: 9px !important;
    cursor: pointer;
    // background-color: #00AEEF;

    &:hover {
      background-color: #e9ecef;
    }
  }
}

.job_content_row {
  border: none;

  .job_content {
    h3 {
      font-size: 22px;
      color: $kgen-black;
      font-weight: bold;
      margin: 0;
    }

    h4 {
      font-size: 15px;
      font-weight: bold;
      color: $kgen-primary;
      margin: 0;

      span {
        color: $kgen-secondary;
      }
    }

    p {
      font-size: 14px;
      color: $kgen-black;
      margin-bottom: 0;
      line-height: 1.3;
    }

    .job_title {
      margin-top: 50px;
    }

    .job_skills {
      margin-top: 15px;
    }

    .job_duties {
      margin-top: 50px;

      h4 {
        margin-bottom: 15px;
      }
    }

    .job_qual {
      margin-top: 25px;

      h4 {
        margin-bottom: 15px;
      }
    }

    .job_contact {
      p {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      a {
        color: $kgen-secondary;
      }

      margin-top: 25px;
    }

    border-bottom: 1px solid $kgen-light-grey !important;
    padding: 0 15px 0px 15px;
  }

  td {
    border: none;
  }
}

.titlePosting {
  color: #01486b;
  font-size: 24px !important;
  font-family: 'Mark-Bold' !important;
  line-height: 29px !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.companyName {
  color: #1e1e1e;
  font-family: 'Mark-Medium' !important;
  font-size: 16px !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 50px;
}
.labelPosting {
  font-size: 12px !important;
  font-family: 'Mark-Medium' !important;
  text-transform: uppercase;
  color: #1e1e1e !important;
}
.valuePosting {
  font-size: 14px !important;
  font-family: 'Mark-Light' !important;
  color: #1e1e1e;
}

.valueEmail {
  font-size: 14px !important;
  font-family: 'Mark-Light' !important;
  color: #469ddb !important;
  word-break: break-all;
}
