@import '~antd/dist/antd.css';
@import '~bootstrap/scss/bootstrap';
@import 'theme';

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  src: url(../fonts/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url(../fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url(../fonts/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: url(../fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  src: url(../fonts/OpenSans-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Mark-Heavy';
  font-weight: 800;
  font-style: normal;
  src: url(../fonts/Mark-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'Mark-Light';
  font-weight: 350;
  font-style: normal;
  src: url(../fonts/Mark-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Mark-Medium';
  font-weight: 550;
  font-style: normal;
  src: url(../fonts/Mark-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Mark-Bold';
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/Mark-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Mark-Book';
  font-weight: 450;
  font-style: normal;
  src: url(../fonts/Mark-Book.ttf) format('truetype');
}