.divider {
  width: 123px;
  height: 0px;
  border: 0.5px solid #d9d9d9;
  transform: rotate(-90deg);
  position: relative;
  right: 100%;
  top: 46%;
}

@media (max-width: 600px) {
  .filterInputs {
    display: block !important;
  }
}

@media (min-width: 630px) and (max-width: 850px) {
  .filterInputs {
    display: block !important;
    min-width: 550px !important;
  }
}

.intershipDetails {
  padding: 60px 115px 0px 155px;
  background-color: #d9d9d9;
  // min-height: 100vh;

}

.filterInputs {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.trainingBox {
  position: relative;
}

@media (max-width:992px) and (min-width:600px) {
  .intershipDetails {
    padding: 60px 60px 0px 60px;
  }
}

@media screen and (min-width: 600px) and (max-width:900px) {
  .intershipDetails {
    padding: 60px 30px 0px 30px;
  }
}
// @media screen and (min-width: 900px) and (max-width:1200px) {
//   .intershipDetails {
//     padding: 60px 20px 0px 20px;
//   }
// }
 
 
@media (max-width: 600px) {
  .intershipDetails {
    padding: 60px 15px 0px 15px;
  }

}

.icon-input {
  display: flex;
}
